import { defineStore } from 'pinia'
import { type ICounterpartyContract } from '~/types/ICounterpartyContract'
import { useAuthenticatedFetch } from '~/composables/useAuthenticatedFetch'

export const useCounterpartyContractsStore = defineStore('counterpartyContracts', () => {
  const contracts = ref<ICounterpartyContract[] | null>([])
  const pending = ref(false)
  const success = ref(false)
  const error = ref<any>(null)
  const fetch = async (counterpartyId: string) => {
    const {
      data,
      pending: _pending,
      error: _error,
      execute
    } = await useAuthenticatedFetch<ICounterpartyContract[]>(`/api/v1/shop/counterparties/${counterpartyId}/contracts`)
    pending.value = _pending.value
    error.value = _error.value
    await execute()
    contracts.value = data.value
    success.value = true
  }
  return {
    fetch,
    error,
    success,
    pending,
    contracts
  }
})
